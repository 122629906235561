import { Logo } from '@mr-yum/frontend-ui'
import cn from 'classnames'
import React from 'react'
import { FormattedMessage } from 'react-intl'

import { useLogger } from '@/hooks/useLogger'

interface Props {
  hasFloatingButton?: boolean | null
}

export const Footer = ({ hasFloatingButton = false }: Props) => {
  const { logEvent } = useLogger()

  return (
    <footer>
      <div className="pb-[max(0px,calc(2*env(safe-area-inset-bottom)))]">
        <div
          className={cn(
            'text-cent mx-auto flex max-w-[288px] flex-col items-center justify-between bg-surface-ui-background px-4 pb-10 pt-6 text-foreground',
            {
              'pb-24': hasFloatingButton,
              'pb-10': !hasFloatingButton,
            },
          )}
        >
          <div className="mb-3 flex items-center justify-center">
            <a
              href="https://www.meandu.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Logo width={80} />
            </a>
          </div>

          <p className="mb-3 text-center text-foreground-subtle my-body-xs">
            <FormattedMessage
              defaultMessage="Find out how 6000+ restaurants use me&u to serve their guests and grow their brands."
              id="sTicYD"
            />
          </p>

          <a
            className="no-underline my-label-sm"
            href="https://www.meandu.com"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => logEvent('Clicked on learn more in footer')}
          >
            <FormattedMessage defaultMessage="Learn more" id="TdTXXf" />
          </a>
        </div>
      </div>
    </footer>
  )
}
