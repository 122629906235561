import {
  convert24To12,
  WEEKDAYS,
} from '@mr-yum/frontend-core/dist/support/dates'
import { Modal } from '@mr-yum/frontend-ui'
import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { isOrderingTypeAvailable } from '@/components/Venue/utils/venueUtils'
import {
  MenuCategoriesQuery,
  MenuVenueQuery,
  OrderingType,
  TimeSlot,
  VenueLandingQuery,
} from '@/gql/graphql'

interface Props {
  className?: string
  venue?:
    | MenuVenueQuery['guestVenue']
    | MenuCategoriesQuery['guestVenue']
    | VenueLandingQuery['guestVenue']
  orderingType: OrderingType
}

export const VenueClosedBanner = ({
  className,
  venue,
  orderingType,
}: Props) => {
  const [open, setOpen] = useState(false)
  const intl = useIntl()

  if (!venue) return null

  if (orderingType === OrderingType.Menu) {
    return null
  }

  const isAcceptingOrders =
    venue.ordering &&
    venue.isOrderingAvailable &&
    isOrderingTypeAvailable(venue as any, orderingType)

  if (!isAcceptingOrders) {
    const isClosed = venue.isClosed

    const venueHours = venue.hours

    const hasTimeSlots: boolean =
      !!venueHours &&
      [
        venueHours.mon,
        venueHours.tue,
        venueHours.wed,
        venueHours.thu,
        venueHours.fri,
        venueHours.sat,
      ].some((day) => day && day.length > 0)

    return (
      <div className={className}>
        <div className="w-full bg-surface-bright p-3 text-foreground">
          <p className="flex-1">
            {isClosed && hasTimeSlots ? (
              <>
                <FormattedMessage
                  defaultMessage="Digital ordering is only available during"
                  id="iwSWzJ"
                />{' '}
                <a
                  className="cursor-pointer text-inherit underline"
                  onClick={() => setOpen(true)}
                  aria-label={intl.formatMessage({
                    defaultMessage: 'these hours',
                    id: 'NzXFWZ',
                  })}
                >
                  <FormattedMessage defaultMessage="these hours." id="3u+C/g" />
                </a>
              </>
            ) : (
              <FormattedMessage
                defaultMessage="Digital ordering is temporarily unavailable. Please talk to a member of our staff."
                id="emPld9"
              />
            )}
          </p>
        </div>

        <Modal
          isOpen={open}
          onClose={() => setOpen(false)}
          title={
            <FormattedMessage
              defaultMessage="{venueName} digital ordering hours"
              id="y4WIID"
              values={{ venueName: venue.name }}
            />
          }
        >
          <div className="-m-4 p-4">
            {Object.entries(WEEKDAYS).map(([key, day]) => {
              const dayHours = venue.hours?.[key as keyof typeof WEEKDAYS] || []
              return (
                <div key={key} className="flex px-4 py-3 odd:bg-surface-bright">
                  <div className="flex-1">{day}</div>
                  <div className="flex-1 text-right">
                    {dayHours.length > 0 ? (
                      dayHours.map((hours: TimeSlot, index: number) => (
                        <div key={index}>
                          {convert24To12(hours.startTime)} –{' '}
                          {convert24To12(hours.endTime)}
                        </div>
                      ))
                    ) : (
                      <div>Closed</div>
                    )}
                  </div>
                </div>
              )
            })}
          </div>
        </Modal>
      </div>
    )
  } else {
    return null
  }
}
