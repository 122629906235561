import { RouteLink } from '@mr-yum/frontend-core/dist/services/routes'
import { Button } from '@mr-yum/frontend-ui'
import cn from 'classnames'
import React from 'react'
import { FormattedMessage } from 'react-intl'

import { FloatingButton } from '@/components/Shared/FloatingButton'
import { useLogger } from '@/hooks/useLogger'
import { OrderingTypeSlug, routes } from '@/lib/routes'

interface Props {
  itemCount?: number
  venueSlug: string
  orderingTypeSlug: OrderingTypeSlug
}

export const ViewOrderButton = ({
  venueSlug,
  orderingTypeSlug,
  itemCount = 0,
}: Props) => {
  const { logEvent } = useLogger()
  const hasCartItems = itemCount > 0

  return (
    <FloatingButton>
      <RouteLink route={routes.cart} pathData={{ venueSlug, orderingTypeSlug }}>
        <Button
          as="a"
          size="lg"
          aria-label="Cart"
          data-testid="view-order-button"
          onClick={() => {
            logEvent('Click cart button')
          }}
          fullWidth
        >
          <span className="flex w-full gap-2">
            {hasCartItems && (
              <span className="block h-5 w-5 flex-shrink-0 rounded bg-[color:var(--action-primary-foreground)] text-center text-[color:var(--action-primary-surface)] my-label-md">
                {itemCount}
              </span>
            )}
            <span
              className={cn('flex-grow text-center', {
                'pr-8': hasCartItems,
              })}
            >
              <FormattedMessage defaultMessage="View order" id="z7+8kB" />
            </span>
          </span>
        </Button>
      </RouteLink>
    </FloatingButton>
  )
}
