import React, { memo } from 'react'

import { Text } from '@/components/Skeleton/Text'

import {
  Item,
  ItemDescription,
  ItemImage,
  ItemName,
  ItemPriceWrapper,
} from './shared'

export const ImageItemSkeleton = memo(() => (
  <Item>
    <div className="flex w-full">
      <ItemImage className="skeleton" />
      <div className="flex-1">
        <div className="flex h-full flex-col justify-between p-3 text-foreground-subtle sm:p-5">
          <div className="flex-1">
            <ItemName>
              <Text className="w-1/2 sm:w-1/3" />
            </ItemName>

            <ItemDescription>
              <Text className="w-full sm:w-3/5" />
            </ItemDescription>
          </div>

          <div className="flex items-center justify-between">
            <ItemPriceWrapper className="my-body-sm-bold">
              <Text chars={3} />
            </ItemPriceWrapper>
          </div>
        </div>
      </div>
    </div>
  </Item>
))
